import { ReactElement, useEffect } from 'react';
import { Font } from 'src/design-system/text/font';
import { Spacer } from 'src/design-system/spacer/Spacer';
// STYLES
import styles from './slice-section.module.scss';
// INTERFACES
import { Link } from '@utmb/design-system/link/link';
import { LinkTypeEnum } from '@utmb/types/enum/LinkTypeEnum';
import { LinkView, SliceSectionView } from 'src/types';
import { Slice } from '@utmb/types/slice-handler';
// UTILS
import { v4 as uuidv4 } from 'uuid';
import { parseMarkdown } from '@utmb/utils/markdown';
import { useGlobal } from '@utmb/contexts/GlobalContext';
import { ColorTypesEnum } from '@utmb/types/colors';

const { FuturaHeavy, FuturaLight } = Font;

export const Section = (props: Slice<SliceSectionView>): ReactElement => {
    const { id, content, summary, title, links, isDark } = props;
    const scriptRef = uuidv4();

    const {
        global: {
            event: { primaryColorIsDark, secondaryColorIsDark },
        },
    } = useGlobal();

    const sectionBlockCSS = [styles.section_block, 'container', isDark ? styles.is_dark : ''].filter(Boolean).join(' ');

    useEffect(() => {
        if (content && content.includes('<script')) {
            const re = /<(script[^>]*)>([\s\S]*?)<\/script>/gm;
            let match: any[] | null;
            const importedScripts: Element[] = [];
            while ((match = re.exec(content))) {
                const reSrc = /src="(.*?)"/gm;
                const src = reSrc.exec(match[1]);
                if (src && src[1]) {
                    const script = document.createElement('script');
                    script.src = src[1];
                    document.getElementById(scriptRef)?.appendChild(script);
                    importedScripts.push(script);
                } else {
                    const script = document.createElement('script');
                    script.innerText = `setTimeout( () => { ${match[2]} }, 1500);`;
                    document.getElementById(scriptRef)?.appendChild(script);
                    importedScripts.push(script);
                }
            }
            return () => {
                importedScripts.forEach((script) => document.getElementById(scriptRef)?.removeChild(script));
            };
        }
    }, [content, scriptRef]);

    return (
        <div id={id} className={sectionBlockCSS}>
            {title && (
                <FuturaHeavy as="h2" mobile="22" desktop="36">
                    {title}
                </FuturaHeavy>
            )}
            {summary && <FuturaLight mobile={'36'}>{summary}</FuturaLight>}
            {content && (
                <div
                    className={`${styles.paragraph} lh-24 lh-d-28 markdown ${primaryColorIsDark ? 'primaryColorIsDark' : ''}`}
                    dangerouslySetInnerHTML={{ __html: parseMarkdown(content) }}
                />
            )}
            <div id={scriptRef}></div>
            {links && !!links.length && (
                <>
                    <Spacer custom="25" />
                    {links.map((link: LinkView) => {
                        const LinkComponent = link.type === LinkTypeEnum.LINK ? Link.Default : Link.Button;

                        const classname = link.type === LinkTypeEnum.LINK ? styles.link : styles.button;

                        return (
                            <LinkComponent
                                href={link.slug}
                                className={classname}
                                label={link.label}
                                followClassName={`${link.followClass}-ga`}
                                key={link.slug}
                                bgcolor={
                                    ((isDark && !secondaryColorIsDark) || (!isDark && secondaryColorIsDark)) && secondaryColorIsDark !== primaryColorIsDark
                                        ? ColorTypesEnum.SECONDARY
                                        : undefined
                                }
                            />
                        );
                    })}
                </>
            )}
        </div>
    );
};
